/* 
 * Copyright 2011-2024, The Trustees of Indiana University and Northwestern
 *   University.  Licensed under the Apache License, Version 2.0 (the "License");
 *   you may not use this file except in compliance with the License.
 * 
 * You may obtain a copy of the License at
 * 
 * http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software distributed
 *   under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 *   CONDITIONS OF ANY KIND, either express or implied. See the License for the
 *   specific language governing permissions and limitations under the License.
 * ---  END LICENSE_HEADER BLOCK  ---
*/

.react-button-container {
  display: inline-block;

  .modal-content {
    height: 100vh;
  }
}

.modal-open .modal {
  height: 100vh;
  overflow: scroll;
}

.sme-modal-wrapper {
  .show {
    opacity: 1;
  }

  .modal-wrapper-body {
    width: 90%;
    top: 50px;
  }

  .modal-title {
    display: inline-block;
  }
}

.modal-backdrop {
  opacity: 0.1;
}
.alert {
  padding-right: 30px;
}
.btn-edit {
  margin-right: -4px;
}

.btn-outline-secondary {
  color: #0a2326;
  border-color: #999;
  background-color: #fff;

  &:hover {
    background-color: #f2f2f2;
    border-color: #999;
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    background-color: #f2f2f2 !important;
    border-color: #999 !important;
    box-shadow: none;
  }
}
